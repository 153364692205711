<template>
  <div id="app">
    <!-- <div class="gradient-bg">
      <div class="blur-1"></div>
      <div class="blur-2"></div>
      <div class="blur-3"></div>
    </div> -->
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">

        <div class="loading-effect" style="top: 50%;left: 50%;">
          <div class="icon-button" :style="{ 'background-image': 'url(' + logo + ')' }"
            style="height: 3rem;width: auto;">
          </div>
        </div>
        <div class="loading-effect">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner">

          </b-spinner>

        </div>
        <div class="loading-effect" style="top: 80%;">
          {{ $t('loading...') }}

        </div>
      </div>
    </div>
    <Header v-if="!($route.path.includes('sessions') || $route.path.includes('register') || $route.path.includes('instruction_letter')) "></Header>

    <b-row class="flex-grow-1">
      <b-col v-if="!$route.path.includes('sessions')" class="d-none d-md-block"
      :md="$route.path.includes('instruction') ? 0 : $route.path.includes('register') ? 4 : 2">
        <div class="auth-layout-wrap h-100 w-100 mx-auto position-relative">
          <div class="background-image"
            :style="{ 'background-image': 'url(' + require('./assets/images/itc_bg/side-bg.png') + ')' }">
            <!-- <img src="./assets/images/itc_logo/logo.png" alt="" height="42px" width="auto" class="mx-auto my-4"> -->
          </div>
        </div>
      </b-col>
      <b-col :md="$route.path.includes('sessions') ? 12 : $route.path.includes('register') ? 8 : 8" class="mx-auto">
        <router-view></router-view>
      </b-col>
    </b-row>
    <Footer v-if="!($route.path.includes('instruction_letter'))"></Footer>
    <PasswordModal></PasswordModal>
    <ModalError></ModalError>
    <b-modal id="modal-news" hide-footer>

      <div class="">
        <img :src="$root.newsItem.public_path" alt="" height="auto" width="100%" style="object-fit: cover;"
          class="rounded-1">
        <div class="text-left mt-3">
          <div class="text-20 font-weight-semibold mb-1">{{ $root.newsItem.title }}</div>
          <div class="text-secondary text-12 mb-2">{{ $root.newsItem.updated_at }}</div>
          <div class="mb-1 text-14" v-html="$root.newsItem.description.replace(/(\r\n|\r|\n)/g, '<br>')"></div>

        </div>

      </div>

    </b-modal>

    <b-modal id="modal-promotion" hide-footer>

      <div class="">
        <img :src="$root.promotionItem.public_path" alt="" height="auto" width="100%" style="object-fit: cover;"
          class="rounded-1">
        <div class="text-left mt-3">
          <div class="text-20 font-weight-semibold mb-1">{{ $root.promotionItem.title }}</div>
          <div class="text-secondary text-12 mb-2">{{ $root.promotionItem.updated_at }}</div>
          <div class="mb-1 text-14" v-html="$root.promotionItem.description.replace(/(\r\n|\r|\n)/g, '<br>')"></div>

        </div>

      </div>

    </b-modal>

    <b-modal id="modal-event" hide-footer>

      <div class="">
        <img :src="$root.eventItem.public_path" alt="" height="auto" width="100%" style="object-fit: cover;"
          class="rounded-1">
        <div class="text-left mt-3">
          <div class="text-20 font-weight-semibold mb-1">{{ $root.eventItem.title }}</div>
          <div class="text-secondary text-12 mb-2">{{ $root.eventItem.updated_at }}</div>
          <div class="mb-1 text-14" v-html="$root.eventItem.description.replace(/(\r\n|\r|\n)/g, '<br>')"></div>

        </div>

      </div>

    </b-modal>


  </div>
</template>

<script>
import Header from './header.vue';
import Footer from './footer.vue';
import ModalError from './errorMsg.vue'
import PasswordModal from './views/manage/security/passwordBUS.vue';
export default {
  components: {
    Header, Footer, PasswordModal, ModalError
  },
  watch: {
    $route() {
      var title = this.$route.path.split('/');
      this.title = title[title.length - 1];
      this.path = this.title;

    }
  },
  name: 'FLT',
  data() {
    return {
      screenWidth: 0,
      title: '',
      path: '',
      username: '',
      address: '',
      logo: require('./assets/images/itc_logo/logo.png')
    }
  },
  methods: {
    copy(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          alert(`${this.$t('copySuccess')}: ` + value);
        })
        .catch((error) => {
          alert(error);
        });
    },
    logout() {
      localStorage.removeItem('currencyToken');
      localStorage.removeItem('info');
      this.$router.push('/web/sessions/login');
    },
  },
  computed: {
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  created() {
    this.screenWidth = screen.width;

  },
  mounted() {
    var title = this.$route.path.split('/');
    this.title = title[title.length - 1];

    this.path = this.title;
    var info = JSON.parse(localStorage.getItem('info'));
    if (info)
      this.$root.username = info.username;
    console.log(this.$root.username);
  }
}
</script>

<style>
#app {
  font-family: 'Manrope' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  /* color: #2c3e50; */
  margin-top: 60px;
}
</style>
