<template>
    <div>
        <div class="header_wrap">
            <!-- v-if="!$route.path.includes('sessions') && !$route.path.includes('register') && !$route.path.includes('set-sec-password')"
            :class="{ 'pb-4': $route.path.includes('homepage') }"> -->

            <div class="content">
                <b-row class="justify-content-between align-items-center">
                    <b-col cols="2">
                        <div class="flex-grow-1 logo d-flex align-items-center">
                            <img :src="require('./assets/images/itc_logo/logo.png')" alt="" height="32px">

                        </div>
                    </b-col>
                    <b-col cols="6" class="d-md-block d-none">
                        <div class="flex-grow-2 d-flex justify-content-around">
                            <router-link tag="a" to="/web/dashboard" class="nav-item-hold">
                                <span class="nav-text" :class="{ 'active': $route.path.includes('dashboard') }">{{
                                    $t("dashboard") }}</span>
                            </router-link>
                            <router-link tag="a" to="/web/subscription/list" class="nav-item-hold">
                                <span class="nav-text" :class="{ 'active': $route.path.includes('subscription') }">{{
                                    $t("i_asset_trust") }}</span>
                            </router-link>
                            <router-link tag="a" to="/web/token/trading" class="nav-item-hold">
                                <span class="nav-text" :class="{ 'active': $route.path.includes('token') }">{{
                                    $t("token_trading") }}</span>
                            </router-link>
                            <router-link tag="a" to="/web/profile" class="nav-item-hold">
                                <span class="nav-text" :class="{ 'active': $route.path.includes('profile') }">{{
                                    $t("profile") }}</span>
                            </router-link>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="d-flex justify-content-end align-items-center">
                            <img v-if="$root.userInfo.rank.id == 0" width="32px"
                                src="../src/assets/images/rank/member.svg" alt="">
                            <img v-else-if="$root.userInfo.rank.id == 1" width="32px"
                                src="../src/assets/images/rank/agent.svg" alt="">
                            <img v-else-if="$root.userInfo.rank.id == 2" width="32px"
                                src="../src/assets/images/rank/manager.svg" alt="">
                            <img v-else-if="$root.userInfo.rank.id == 3" width="32px"
                                src="../src/assets/images/rank/unit_manager.svg" alt="">
                            <img v-else-if="$root.userInfo.rank.id == 4" width="32px"
                                src="../src/assets/images/rank/agency_manager.svg" alt="">
                            <div class="" style="">
                                <b-dropdown id="dropdown-lang" right variant="link" toggle-class="my-dropdown ml-4">

                                    <template #button-content>
                                        <!-- <img :src="require(`./assets/images/flag/${$i18n.locale}.png`)" alt="" width="20px"> -->
                                        <span class="text-orange text-16 font-weight-bold text-uppercase">{{
                                            $i18n.locale }}</span>
                                    </template>
                                    <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
                                    <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
                                    <b-dropdown-item href="#" @click="$root.changeLan('in')">Indon</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div id="tooltip-button-1" class="text-20 ml-4 text-orange"
                                @click="$router.push('/web/announcement')">
                                <i class="fa-regular fa-bell"></i>
                            </div>

                            <b-tooltip :show.sync="show" target="tooltip-button-1" placement="bottom">
                                <div class="title-news text-left">
                                    {{ $t('news') }}
                                </div>
                                <div class="title-content d-flex" @click="openNews(item)" v-for="item in $root.newsList"
                                    :key="item.id">
                                    <img :src="item.public_path" alt="" height="48px" width="48px">
                                    <div class="content ml-2 text-left">
                                        <div class="text-14 font-weight-semibold mb-1 text-ellipsis"
                                            style="max-width: 340px;">{{ item.title }}
                                        </div>
                                        <div class="text-secondary mb-1 max-line-2 text-14">{{ item.description }}</div>
                                        <div class="text-secondary text-12">{{ item.updated_at }}</div>

                                    </div>

                                </div>
                            </b-tooltip>
                            <div class="text-20 text-orange ml-4 d-md-none d-block" v-b-toggle.sidebar-right>
                                <i class="fa-solid fa-list"></i>
                            </div>
                            <div id="tooltip-button-1" class="text-20 ml-4 text-orange" @click="$parent.logout">
                                <i class="fa fa-sign-out"></i>
                            </div>

                        </div>
                    </b-col>
                </b-row>

                <!-- <div class="justify-content align-items-end mt-4 mb-2 d-flex"
                    v-if="$route.path.includes('homepage')">
                    <h3 class="mt-3 text-white text-20 font-weight-semibold">{{ $t('welcome') }} {{ $root.username }}</h3>
                </div> -->

            </div>
        </div>
        <div class="d-flex justify-content align-items-end d-sm-none"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register') && !$route.path.includes('homepage')">
            <!-- <h3 class="my-3 mx-4 text-20 font-weight-semibold">{{ $t($parent.title) }}</h3> -->
        </div>
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow backdrop>
            <div class="profile-outer-box mb-4">
                <div class="text-left">
                    <img :src="require('./assets/images/logo/sidebar-logo.png')" alt="">
                </div>
                <!-- <div class="profile-box">
                    <span class="text-64">
                        <i class="text-orange fa-regular fa-circle-user"></i>
                    </span>
                    <img :src="require('./assets/images/xlogo_signin/top_xlogo.png')" alt="" width="100%">
                    <h4 class="text-18 mb-4 mt-2 text-center">{{ $root.username }}</h4>
                    <b-button variant="primary" @click="$router.push('/web/trade')">{{ $t('start_trading') }}</b-button>

                </div> -->
            </div>
            <!-- <div class="">
                <router-link tag="a" to="/web/dashboard" class="nav-item-hold">
                    <span class="nav-text" :class="{'active':$route.path.includes('dashboard') }">{{ $t("dashboard") }}</span>
                </router-link>
                <router-link tag="a" to="/web/subscription/list" class="nav-item-hold">
                    <span class="nav-text" :class="{'active':$route.path.includes('subscription') }">{{ $t("i_asset_trust") }}</span>
                </router-link>
                <router-link tag="a" to="/web/token/trading" class="nav-item-hold">
                    <span class="nav-text" :class="{'active':$route.path.includes('token') }">{{ $t("token_trading") }}</span>
                </router-link>
                <router-link tag="a" to="/web/profile" class="nav-item-hold">
                    <span class="nav-text" :class="{'active':$route.path.includes('profile') }">{{ $t("profile") }}</span>
                </router-link>
            </div> -->

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/dashboard" class="">
                    <span class="nav-text">
                        {{ $t("dashboard") }}
                    </span>
                </router-link>
            </div>
            <div class="text-left mt-2 mb-4">
                <router-link tag="a" to="/web/subscription/list" class="">
                    <span class="nav-text">
                        {{ $t("i_asset_trust") }}
                    </span>
                </router-link>
            </div>
            <div class="text-left mt-2 mb-4">
                <router-link tag="a" to="/web/token/trading" class="">
                    <span class="nav-text">
                        {{ $t("token_trading") }}
                    </span>
                </router-link>
            </div>
            <div class="text-left mt-2 mb-4">
                <b-dropdown id="profile-dropdown">
                    <template #button-content>
                        <span class="nav-text pl-0">{{ $t('profile') }}</span>
                    </template>
                    <b-dropdown-item :to="{ path: '/web/profile?type=1' }">
                        <span class="nav-text text-14 mb-2">
                            {{ $t("account_setting") }}
                        </span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ path: '/web/profile?type=2' }">
                        <span class="nav-text text-14">
                            {{ $t("security_center") }}
                        </span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ path: '/web/profile?type=3' }">
                        <span class="nav-text text-14">
                            {{ $t("bank_details") }}
                        </span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ path: '/web/profile?type=4' }">
                        <span class="nav-text text-14">
                            {{ $t("affiliate") }}
                        </span>
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ path: '/web/profile?type=5' }">
                        <span class="nav-text text-14">
                            {{ $t("genealogy") }}
                        </span>
                    </b-dropdown-item>
                </b-dropdown>
            </div>





            <!-- <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=1" class="">
                    <span class="nav-text">
                        {{ $t("account_info") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=2" class="">
                    <span class="nav-text">
                        {{ $t("sec_password") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=3" class="">
                    <span class="nav-text">
                        {{ $t("kyc") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=4" class="">
                    <span class="nav-text">
                        {{ $t("withdrawal") }}
                    </span>
                </router-link>
            </div> -->

            <div class="flex-grow-1"></div>

            <div class="text-left mb-4">
                <span class="nav-text logout" @click="$parent.logout">
                    {{ $t("logout") }}
                </span>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show: false,
        }
    },
    methods: {
        openNews(item) {
            this.show = !this.show;
            this.$root.newsItem = item;
            this.$bvModal.show('modal-news');

        }
    }

}
</script>

<style scoped>
#sidebar-right.b-sidebar {
    background-color: black !important;
}
</style>